import * as Sentry from "@sentry/react";
import React, { lazy } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./assets/fonts/Roobert/roobert.css";
import "./assets/fonts/nib/nib.css";
import "./styles/globals.css";
import "./styles/reset.css";
import "./styles/tailwind.css";

if (process.env.NODE_ENV === "production" && import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: process.env.NODE_ENV,
    initialScope: {
      tags: { mode: import.meta.env.VITE_MODE },
    },
  });
}

let App;

if (import.meta.env.VITE_MODE === "show") {
  App = lazy(() => import("./show/ShowApp.jsx"));
} else {
  App = lazy(() => import("./App.jsx"));
}

let StagingBanner;
let MaintenanceMessage;

if (process.env.NODE_ENV.startsWith("staging")) {
  StagingBanner = lazy(() => import("./staging/StagingBanner.jsx"));
}

if (import.meta.env.VITE_MAINTENANCE_DATE) {
  MaintenanceMessage = lazy(
    () => import("./maintenance/MaintenanceMessage.jsx"),
  );
}

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      {StagingBanner && <StagingBanner />}
      {MaintenanceMessage && <MaintenanceMessage />}
      <App />
    </BrowserRouter>
  </React.StrictMode>,
);
